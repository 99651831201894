import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1024.000000 1024.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} /> 
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)">
         <path d="M2757 7943 c-4 -3 -7 -494 -7 -1089 l0 -1083 23 -18 c13 -10 41 -27
63 -38 21 -11 41 -22 44 -25 7 -8 157 -80 167 -80 4 0 20 -6 34 -14 89 -46
398 -119 561 -131 l87 -7 0 318 c1 176 1 322 1 327 0 17 18 4 33 -25 10 -18
25 -45 35 -61 9 -15 91 -147 181 -293 l164 -267 106 7 c147 10 456 66 636 115
137 38 388 127 452 160 10 5 43 21 73 35 101 49 131 68 126 82 -3 8 0 11 10 7
7 -3 22 -2 31 1 15 6 15 8 3 17 -12 9 -11 10 7 6 23 -5 78 25 66 36 -3 4 -15
7 -25 7 -14 0 -19 6 -16 22 4 27 68 46 68 21 0 -12 3 -12 16 1 8 9 20 16 25
16 15 0 10 -18 -8 -29 -10 -5 -15 -12 -12 -15 3 -3 20 5 37 18 l32 23 -30 6
c-16 4 -37 4 -45 1 -12 -5 -15 3 -15 40 l0 46 -40 0 -40 0 0 278 c0 203 -3
281 -12 290 -9 9 -9 12 0 12 7 0 12 13 12 30 0 20 5 30 15 30 17 0 21 35 5 45
-17 10 -11 25 13 34 l22 9 -23 9 c-21 8 -22 10 -8 22 9 8 16 18 16 24 0 6 -11
-4 -25 -23 -13 -18 -22 -36 -20 -41 3 -4 2 -9 -3 -11 -14 -5 -52 -65 -52 -81
0 -8 -6 -17 -13 -20 -7 -2 -26 -30 -42 -61 -16 -31 -33 -56 -37 -56 -4 0 -8
-8 -8 -17 0 -10 -14 -36 -30 -57 -17 -22 -30 -45 -30 -52 0 -6 -7 -18 -17 -25
-9 -8 -22 -27 -30 -44 -7 -16 -21 -42 -31 -57 -16 -25 -74 -122 -97 -163 -6
-11 -27 -45 -46 -75 -20 -30 -46 -73 -58 -95 -12 -22 -29 -52 -39 -67 -9 -14
-34 -56 -55 -92 -21 -36 -44 -66 -52 -66 -7 0 -28 24 -45 53 -17 28 -47 77
-66 107 -19 30 -39 64 -44 75 -5 11 -20 37 -32 57 -13 20 -137 225 -276 455
-139 230 -257 425 -263 433 -5 8 -27 44 -48 80 -22 36 -88 146 -148 245 -59
98 -112 187 -118 196 -61 104 -80 135 -85 139 -4 3 -9 10 -11 16 -2 6 -47 81
-99 167 -60 99 -102 158 -115 161 -29 8 -840 7 -848 -1z m2829 -1227 c-11 -48
-27 -55 -18 -9 4 23 19 53 26 53 1 0 -2 -20 -8 -44z m-88 -136 c-7 -11 -16
-20 -20 -20 -5 0 -3 9 4 20 7 11 16 20 20 20 5 0 3 -9 -4 -20z m122 -524 c-1
-5 4 -12 11 -17 10 -6 10 -11 0 -23 -7 -9 -17 -13 -22 -10 -13 8 -11 66 2 62
6 -2 10 -7 9 -12z m7 -142 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z"/>
<path d="M7362 7850 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7405 7800 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7381 7774 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7338 7724 c-3 -34 -6 -39 -30 -42 -26 -3 -28 -7 -28 -45 0 -39 -16
-131 -54 -329 -14 -69 -30 -135 -66 -263 -31 -110 -76 -228 -141 -371 -11 -22
-19 -43 -19 -45 0 -22 -172 -305 -239 -394 -17 -22 -34 -47 -38 -55 -4 -8 -12
-20 -17 -26 -6 -6 -36 -39 -66 -73 -84 -95 -167 -181 -174 -181 -4 0 -4 5 -1
10 3 6 1 10 -4 10 -6 0 -10 -10 -8 -22 1 -14 -4 -24 -13 -24 -8 -1 -21 -2 -29
-3 -11 -1 -11 -4 -1 -16 14 -16 7 -19 -37 -15 -13 1 -23 -3 -23 -9 0 -6 5 -11
10 -11 19 0 10 -17 -17 -29 -24 -11 -25 -10 -13 4 7 8 10 19 6 23 -4 4 -12 0
-16 -8 -26 -43 -30 -46 -39 -31 -8 13 15 72 32 79 4 2 7 53 7 113 0 90 -3 109
-15 109 -18 0 -19 12 -3 28 20 20 -7 40 -32 24 -10 -6 -25 -10 -32 -7 -7 3
-33 -14 -58 -36 -25 -23 -62 -53 -82 -66 -21 -14 -38 -29 -38 -32 0 -4 -15
-15 -32 -26 -18 -10 -38 -24 -45 -29 -32 -28 -43 -36 -48 -36 -4 0 -21 -11
-39 -25 -18 -14 -38 -25 -44 -25 -7 0 -12 -4 -12 -8 0 -5 -10 -14 -22 -21 -13
-6 -36 -20 -53 -31 -79 -51 -305 -166 -435 -222 -73 -32 -412 -135 -530 -162
-333 -76 -591 -101 -941 -92 -252 6 -479 39 -656 96 -208 68 -345 129 -477
214 -54 35 -86 50 -86 42 0 -8 3 -16 8 -18 4 -1 23 -23 42 -48 19 -25 40 -51
47 -58 10 -11 13 -160 13 -698 l0 -684 363 1 c199 1 354 3 345 6 -24 6 -23 22
4 50 l21 23 -21 0 c-19 0 -22 6 -22 40 0 29 4 40 15 40 11 0 15 11 15 40 0 29
-4 40 -15 40 -12 0 -15 16 -15 80 0 64 3 80 15 80 11 0 15 11 15 40 0 29 -4
40 -15 40 -11 0 -15 11 -15 40 0 29 4 40 15 40 12 0 15 15 15 79 0 67 -3 80
-17 84 -17 4 -17 5 0 6 25 2 21 21 -5 21 -16 0 -19 3 -11 11 8 8 25 8 60 1 52
-12 174 -25 184 -20 3 2 13 -3 22 -10 15 -12 16 -6 19 70 3 82 3 83 28 81 272
-19 408 -24 515 -18 l131 6 76 -118 c70 -110 154 -247 204 -332 l21 -36 236
-3 236 -2 28 47 c15 26 33 55 38 65 6 9 25 43 44 75 19 32 39 67 45 78 6 11
30 52 53 90 22 39 44 81 48 93 6 22 5 23 -16 17 -21 -7 -22 -6 -10 8 9 12 18
14 31 7 13 -7 19 -4 27 13 6 12 26 42 45 67 19 25 45 65 58 90 14 25 28 47 31
50 4 3 19 28 33 57 15 28 33 56 40 62 22 18 147 81 161 81 7 0 15 3 17 8 2 4
32 23 68 41 36 19 67 37 70 40 3 3 28 18 55 33 28 15 49 33 47 39 -2 6 4 9 12
6 8 -3 20 1 27 9 9 11 15 12 25 3 11 -9 14 -61 14 -254 0 -133 3 -458 7 -723
l6 -482 366 1 c201 0 360 3 354 6 -10 4 -13 189 -13 882 0 621 -3 880 -11 888
-11 11 2 48 26 68 15 13 126 153 147 185 14 22 32 45 70 90 9 10 15 68 19 180
l5 165 56 115 c129 267 175 398 219 625 16 84 16 228 0 233 -14 5 -15 79 -1
84 6 2 6 8 0 18 -8 13 -10 12 -10 -3 0 -32 -35 -16 -38 18 -5 47 -20 52 -24 9z
m-1107 -1661 c-15 -21 -41 -30 -53 -17 -9 9 30 44 44 40 7 -1 10 -1 6 1 -4 2
-4 12 -1 20 6 14 7 14 13 -3 4 -11 1 -28 -9 -41z m-41 -33 c3 0 -3 -5 -13 -11
-10 -6 -14 -15 -10 -22 5 -9 3 -9 -8 0 -8 7 -26 10 -40 7 -13 -2 -17 -1 -9 2
8 4 19 13 23 20 7 12 18 13 57 4z m49 -62 c5 -15 4 -26 -3 -30 -18 -12 -24 -9
-21 10 2 9 4 23 4 30 1 19 11 14 20 -10z m-282 -94 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m-37 -24 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3
6 8 10 11 10 2 0 4 -4 4 -10z m340 -100 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-140 -90 c-20 -13 -40 -13 -40 0 0 6 12
10 28 10 21 0 24 -2 12 -10z m-10 -24 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4
0 6 5 11 10 11 6 0 10 -2 10 -4z m-670 -576 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M7433 7640 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M5810 7159 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5746 7064 c-8 -21 -7 -31 2 -18 4 6 16 16 27 22 20 11 20 11 -1 12
-12 0 -25 -7 -28 -16z"/>
<path d="M5713 7008 c-15 -19 -23 -48 -14 -48 10 0 36 48 29 55 -3 3 -10 0
-15 -7z"/>
<path d="M5730 6970 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5666 6883 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M5650 6849 c0 -8 7 -23 15 -33 15 -19 15 -19 15 1 0 11 -7 26 -15 33
-12 10 -15 10 -15 -1z"/>
<path d="M6515 6790 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6370 6711 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M6438 6696 c-10 -7 -18 -16 -18 -19 0 -12 28 4 34 19 6 17 6 17 -16
0z"/>
<path d="M6385 6640 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M6530 6439 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M6505 6410 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6453 6352 c-12 -14 -20 -29 -17 -31 7 -8 47 36 43 47 -2 6 -14 -1
-26 -16z"/>
<path d="M6427 6294 c-4 -4 -7 -14 -7 -23 0 -11 3 -12 11 -4 6 6 10 16 7 23
-2 6 -7 8 -11 4z"/>
<path d="M6370 6260 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6315 6206 c-22 -22 -31 -33 -20 -23 12 9 31 17 43 17 17 0 22 6 22
25 0 14 -1 24 -2 24 -2 -1 -21 -20 -43 -43z"/>
<path d="M6388 6210 c-3 -16 -1 -30 4 -30 4 0 8 14 8 30 0 17 -2 30 -4 30 -2
0 -6 -13 -8 -30z"/>
<path d="M6328 6163 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M6325 6139 c-4 -6 -4 -13 -1 -16 8 -8 36 5 36 17 0 13 -27 13 -35 -1z"/>
<path d="M6385 6090 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5760 6060 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5761 6014 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5795 6020 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M6505 5970 c-16 -17 -25 -30 -19 -30 12 0 58 49 52 55 -2 3 -17 -9
-33 -25z"/>
<path d="M6400 5909 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4933 4113 c-13 -2 -23 -5 -24 -6 -1 -3 -48 -339 -68 -487 -4 -36 -7
-67 -5 -69 8 -10 74 -3 88 8 14 12 23 11 56 -4 74 -33 151 9 194 108 49 110
42 223 -15 271 -37 31 -72 33 -121 6 -20 -11 -39 -16 -42 -11 -5 7 0 42 21
159 5 31 5 32 -28 31 -19 -1 -44 -4 -56 -6z m148 -275 c13 -24 5 -118 -13
-152 -22 -41 -64 -58 -97 -37 -18 12 2 167 26 193 23 25 69 23 84 -4z"/>
<path d="M2402 3940 c-39 -24 -42 -24 -42 -5 0 11 -12 15 -44 15 -25 0 -47 -4
-50 -8 -9 -15 -58 -384 -51 -390 2 -3 26 -7 53 -10 l49 -4 16 108 c10 60 17
121 17 135 0 32 35 79 60 79 19 0 32 -22 25 -42 -2 -7 -11 -65 -19 -129 -8
-64 -16 -122 -18 -130 -4 -16 32 -23 77 -15 l33 6 7 78 c9 99 30 199 45 218
14 15 46 19 54 5 3 -4 -2 -62 -11 -127 -23 -172 -23 -178 5 -177 12 0 38 1 57
2 34 1 35 3 35 39 0 20 7 78 15 127 22 134 20 199 -7 225 -26 24 -64 26 -119
3 -38 -15 -43 -15 -69 0 -37 22 -78 21 -118 -3z"/>
<path d="M2884 3946 c-69 -30 -120 -119 -130 -223 -8 -84 10 -133 58 -163 41
-25 121 -27 178 -4 37 15 40 19 40 56 0 34 -3 39 -17 33 -10 -4 -39 -10 -66
-13 -37 -3 -52 -1 -67 13 -25 23 -27 65 -2 66 105 4 179 12 186 19 17 17 19
114 3 153 -28 67 -110 95 -183 63z m71 -82 c9 -9 15 -28 13 -43 -3 -23 -8 -26
-45 -29 -37 -3 -43 0 -43 16 0 21 36 72 50 72 5 0 16 -7 25 -16z"/>
<path d="M3297 3940 c-30 -24 -37 -25 -37 -4 0 12 -10 14 -47 12 -47 -3 -48
-4 -54 -43 -12 -68 -39 -278 -42 -320 -2 -37 0 -40 27 -43 59 -7 72 3 85 65 6
32 14 92 17 133 8 86 32 120 84 120 33 0 50 26 50 76 0 20 -5 24 -29 24 -15 0
-40 -9 -54 -20z"/>
<path d="M3551 3947 c-42 -16 -97 -78 -112 -126 -17 -58 -23 -165 -10 -202 23
-71 119 -100 211 -63 37 15 41 20 38 48 -3 31 -3 31 -56 28 -69 -5 -82 7 -82
72 0 66 14 116 41 145 19 20 28 22 66 17 43 -6 44 -5 59 30 11 26 12 40 5 47
-16 16 -121 18 -160 4z"/>
<path d="M4243 3945 c-48 -20 -75 -70 -70 -124 4 -38 13 -52 56 -93 78 -73 65
-124 -26 -99 -51 15 -50 15 -68 -19 -20 -39 -20 -37 21 -55 45 -19 124 -19
168 -1 42 18 76 72 76 122 0 34 -7 45 -60 96 -33 31 -60 64 -60 73 0 22 38 33
80 24 41 -10 60 7 60 52 0 22 -6 28 -31 33 -51 10 -112 6 -146 -9z"/>
<path d="M3786 3938 c-3 -7 -7 -38 -10 -68 -4 -30 -10 -77 -16 -105 -5 -27 -9
-80 -9 -117 -1 -57 2 -70 21 -88 34 -32 116 -24 155 16 8 8 17 13 20 10 11
-10 -11 -96 -29 -116 -19 -20 -27 -22 -88 -16 -51 5 -67 4 -68 -6 0 -7 -4 -23
-7 -35 -10 -31 24 -45 110 -47 159 -2 178 33 225 419 5 44 13 99 16 123 l7 42
-56 0 c-64 0 -55 20 -82 -170 -16 -113 -31 -140 -81 -140 -22 0 -24 4 -24 48
0 26 5 76 10 112 6 36 13 84 16 108 l7 42 -57 0 c-37 0 -58 -4 -60 -12z"/>
<path d="M4496 3918 c-3 -18 -12 -76 -21 -129 -25 -161 -17 -231 29 -243 41
-11 81 -6 115 15 34 20 35 20 50 2 9 -11 22 -18 29 -17 6 1 25 2 41 3 l28 1
17 118 c9 64 21 144 26 178 17 105 17 104 -40 104 -62 0 -63 -2 -83 -159 -13
-99 -18 -117 -38 -132 -13 -10 -34 -19 -46 -19 -21 0 -23 5 -23 48 0 26 7 90
15 142 8 52 15 101 15 108 0 8 -17 12 -55 12 -53 0 -54 0 -59 -32z"/>
<path d="M5544 3925 c-58 -29 -87 -70 -108 -153 -50 -190 37 -291 206 -240 41
12 56 38 43 78 -6 18 -12 20 -56 14 -33 -4 -54 -3 -64 6 -20 17 -20 108 1 161
19 47 48 62 103 53 36 -6 39 -4 45 22 4 16 9 36 12 45 3 12 -5 20 -27 28 -51
17 -101 13 -155 -14z"/>
<path d="M5886 3935 c-80 -28 -136 -130 -136 -249 0 -114 48 -166 153 -166
123 0 193 82 204 241 7 97 -11 139 -71 169 -49 23 -95 25 -150 5z m88 -97 c38
-54 -6 -228 -57 -228 -33 1 -42 19 -40 86 3 81 18 129 46 149 28 20 33 19 51
-7z"/>
<path d="M6332 3933 c-25 -12 -36 -13 -45 -4 -6 6 -30 11 -54 11 -54 0 -54 -2
-78 -170 -28 -198 -32 -235 -28 -242 5 -10 107 3 114 15 4 5 14 66 23 136 18
132 35 171 71 157 18 -6 17 -37 -5 -161 -5 -27 -11 -72 -14 -100 l-6 -50 57 0
57 0 12 75 c7 41 16 103 20 137 6 63 30 103 59 103 19 0 19 -25 -1 -172 -9
-65 -14 -124 -11 -130 4 -11 44 -15 100 -9 16 1 36 122 44 266 5 96 4 113 -11
128 -28 28 -65 31 -122 12 -48 -16 -54 -16 -75 -1 -30 20 -65 20 -107 -1z"/>
<path d="M7010 3930 c-43 -23 -50 -24 -50 -5 0 23 -86 21 -99 -2 -5 -10 -16
-76 -26 -148 -9 -71 -20 -155 -24 -185 -5 -30 -8 -56 -7 -56 0 -1 25 -4 54 -6
64 -6 64 -7 82 127 17 130 26 160 54 175 39 20 59 7 54 -38 -26 -208 -31 -262
-27 -265 2 -2 29 -1 59 2 l54 6 18 120 c9 66 17 148 17 183 1 54 -2 66 -24 87
-30 31 -85 33 -135 5z"/>
<path d="M7325 3935 c-81 -28 -136 -135 -137 -262 0 -74 13 -111 51 -140 22
-17 86 -16 122 3 17 8 33 13 36 10 6 -6 -16 -77 -30 -94 -8 -10 -31 -13 -81
-10 l-69 3 -9 -30 c-12 -43 -5 -51 52 -65 94 -22 182 4 220 65 18 28 24 61 50
235 32 223 38 275 34 282 -9 14 -91 9 -104 -7 -10 -12 -17 -12 -48 5 -43 23
-36 23 -87 5z m90 -108 c17 -13 17 -19 5 -96 -11 -72 -16 -83 -39 -95 -58 -28
-78 7 -61 109 5 33 10 61 10 62 0 1 7 9 16 17 19 20 44 20 69 3z"/>
<path d="M5295 3605 c-29 -28 -31 -47 -9 -69 34 -34 84 -8 84 44 0 19 -7 33
-19 40 -27 14 -29 13 -56 -15z"/>
<path d="M6698 3619 c-30 -17 -39 -45 -26 -73 15 -34 50 -34 77 0 27 34 26 49
-1 68 -26 19 -27 19 -50 5z"/>
<path d="M3545 3259 c-8 -25 6 -42 31 -37 12 2 20 13 22 31 3 23 0 27 -22 27
-16 0 -27 -7 -31 -21z"/>
<path d="M4066 3263 c-8 -21 17 -48 38 -40 9 4 16 18 16 32 0 20 -5 25 -24 25
-13 0 -27 -8 -30 -17z"/>
<path d="M6216 3265 c-9 -25 5 -46 28 -43 15 2 22 11 24 31 3 23 0 27 -21 27
-14 0 -27 -7 -31 -15z"/>
<path d="M3430 3095 l0 -165 25 0 25 0 0 165 0 165 -25 0 -25 0 0 -165z"/>
<path d="M4738 3223 l-3 -38 -60 -10 c-72 -12 -85 -30 -85 -123 0 -61 11 -99
34 -114 17 -12 54 -9 76 4 16 10 24 10 40 0 49 -30 51 -24 48 151 -3 158 -4
162 -25 165 -19 3 -22 -2 -25 -35z m-3 -158 c0 -54 -2 -61 -27 -75 -41 -22
-62 -5 -66 53 -4 67 10 89 56 85 l37 -3 0 -60z"/>
<path d="M4960 3112 c0 -177 3 -182 92 -182 82 0 99 18 106 108 8 119 -24 161
-101 129 -19 -9 -38 -13 -41 -9 -3 3 -6 27 -6 54 0 45 -2 48 -25 48 l-25 0 0
-148z m138 6 c7 -7 12 -35 12 -63 0 -61 -22 -88 -67 -83 -27 3 -28 5 -31 63
-3 52 0 61 20 77 27 21 49 23 66 6z"/>
<path d="M4200 3199 c0 -11 -7 -28 -16 -37 -15 -14 -15 -17 0 -27 12 -10 16
-30 16 -93 0 -72 2 -83 22 -96 29 -21 78 -21 78 -1 0 9 -11 20 -25 25 -24 9
-25 14 -25 85 0 68 2 75 20 75 13 0 20 7 20 19 0 10 -9 21 -20 24 -12 3 -20
14 -20 26 0 16 -6 21 -25 21 -19 0 -25 -5 -25 -21z"/>
<path d="M2890 3072 c0 -93 3 -111 18 -125 23 -21 54 -22 93 -2 26 14 30 14
35 0 3 -8 15 -15 25 -15 18 0 19 9 19 125 0 118 -1 125 -20 125 -18 0 -20 -7
-20 -85 0 -79 -2 -86 -24 -101 -14 -8 -35 -14 -48 -12 -22 3 -23 8 -28 98 -5
89 -6 95 -27 98 -23 3 -23 2 -23 -106z"/>
<path d="M3167 3173 c-4 -3 -7 -60 -7 -125 l0 -118 25 0 c25 0 25 1 25 83 0
95 15 121 67 115 l28 -3 3 -97 c3 -97 3 -98 27 -98 25 0 25 0 25 99 0 90 -2
103 -23 127 -23 27 -24 27 -77 13 -42 -11 -54 -11 -58 -1 -4 13 -24 16 -35 5z"/>
<path d="M3677 3173 c-4 -3 -7 -60 -7 -125 l0 -119 27 3 c26 3 26 3 24 83 -1
44 3 86 7 93 5 8 25 14 43 15 l34 2 3 -97 c3 -97 3 -98 27 -98 25 0 25 1 25
85 0 79 2 86 25 101 13 8 32 14 42 12 16 -3 18 -16 21 -101 3 -96 3 -97 27
-97 25 0 25 0 25 105 0 92 -3 108 -20 125 -23 23 -40 24 -90 6 -29 -11 -39
-10 -61 3 -25 14 -31 14 -62 -1 -31 -15 -37 -15 -47 -2 -13 15 -32 18 -43 7z"/>
<path d="M4368 3149 c-30 -29 -30 -32 -26 -105 4 -57 10 -80 25 -95 41 -42
139 -33 160 14 20 43 -15 67 -39 27 -8 -13 -24 -20 -44 -20 -37 0 -57 20 -52
52 3 22 8 23 73 23 l70 0 3 38 c3 30 -2 43 -27 67 -42 43 -100 42 -143 -1z
m110 -21 c7 -7 12 -20 12 -30 0 -15 -9 -18 -50 -18 -37 0 -50 4 -50 14 0 37
61 61 88 34z"/>
<path d="M5217 3173 c-4 -3 -7 -56 -7 -118 0 -111 8 -139 36 -128 10 4 14 28
14 94 0 94 8 109 59 109 16 0 21 6 21 25 0 28 -6 30 -46 15 -19 -8 -30 -8 -38
0 -11 11 -30 13 -39 3z"/>
<path d="M5423 3169 c-32 -17 -46 -60 -41 -133 5 -75 23 -102 74 -112 41 -7
77 0 98 21 39 39 43 162 7 207 -20 24 -103 34 -138 17z m93 -43 c15 -11 19
-26 18 -69 0 -64 -15 -87 -55 -87 -36 0 -49 24 -49 89 0 72 38 102 86 67z"/>
<path d="M5628 3174 c-4 -3 5 -45 18 -92 14 -48 28 -100 32 -117 6 -23 14 -30
31 -30 21 0 27 9 45 65 12 36 25 64 31 63 5 -1 19 -30 30 -65 16 -49 25 -64
41 -66 18 -3 24 5 38 50 8 29 24 82 35 118 20 67 16 84 -15 78 -12 -2 -21 -17
-27 -43 -30 -135 -33 -137 -63 -35 -26 90 -52 101 -72 32 -6 -20 -16 -54 -22
-75 -14 -46 -25 -41 -34 15 -11 72 -26 108 -45 108 -10 0 -20 -3 -23 -6z"/>
<path d="M6012 3164 c-19 -13 -22 -24 -20 -57 3 -43 15 -54 82 -74 41 -12 57
-40 32 -59 -27 -20 -53 -18 -73 6 -22 25 -53 26 -53 2 0 -32 35 -54 88 -55 64
-2 92 20 92 74 0 44 -21 63 -82 74 -35 7 -50 25 -41 53 6 17 58 15 73 -3 18
-22 50 -19 50 4 0 46 -100 69 -148 35z"/>
<path d="M6340 3057 c0 -68 3 -126 6 -129 3 -3 14 -4 25 -1 17 5 19 15 19 91
0 93 10 111 61 112 23 0 28 -5 34 -36 3 -20 5 -64 4 -98 -3 -60 -2 -61 24 -64
l27 -3 0 90 c0 103 -10 137 -44 152 -19 9 -33 8 -65 -4 -34 -14 -41 -14 -51
-2 -7 8 -19 15 -26 15 -11 0 -14 -25 -14 -123z"/>
<path d="M6634 3168 c-29 -14 -44 -49 -44 -104 0 -88 47 -132 109 -101 16 8
32 12 35 9 11 -10 6 -58 -6 -70 -15 -15 -54 -16 -63 -2 -10 16 -55 12 -55 -5
0 -20 52 -45 95 -45 25 0 43 8 60 25 24 23 25 29 25 165 l0 140 -67 -1 c-38 0
-78 -5 -89 -11z m104 -90 c-3 -45 -7 -55 -30 -67 -24 -14 -28 -14 -47 3 -23
21 -26 50 -11 90 8 22 16 26 50 26 l41 0 -3 -52z"/>
<path d="M3550 3050 l0 -120 25 0 25 0 0 120 0 120 -25 0 -25 0 0 -120z"/>
<path d="M4070 3050 l0 -120 25 0 25 0 0 120 0 120 -25 0 -25 0 0 -120z"/>
<path d="M6220 3050 l0 -120 25 0 25 0 0 120 0 120 -25 0 -25 0 0 -120z"/>
<path d="M6873 2983 c-22 -8 -15 -51 8 -58 27 -9 42 6 37 36 -3 24 -21 32 -45
22z"/>
<path d="M7002 2981 c-8 -4 -12 -19 -10 -32 2 -18 9 -24 28 -24 19 0 26 6 28
24 3 20 -11 41 -28 41 -3 0 -11 -4 -18 -9z"/>
<path d="M7147 2983 c-13 -3 -17 -12 -15 -31 2 -21 8 -27 28 -27 21 0 25 5 25
29 0 31 -8 37 -38 29z"/>
<path d="M9028 2255 c-4 -4 -42 -11 -85 -16 -265 -30 -539 -246 -627 -494 -10
-27 -22 -59 -27 -70 -12 -27 -32 -169 -32 -230 -3 -388 278 -725 663 -798 71
-13 219 -13 290 0 219 41 435 189 543 373 78 134 137 354 124 460 -34 273
-138 468 -322 604 -123 91 -237 139 -372 157 -43 6 -94 13 -113 16 -19 2 -38
2 -42 -2z m-160 -285 c83 -27 171 -75 260 -139 78 -57 90 -75 42 -61 -56 16
-174 24 -166 11 4 -6 1 -11 -6 -12 -18 -1 47 -19 104 -28 60 -9 68 -17 26 -25
-32 -6 -98 -45 -98 -58 0 -3 8 -9 18 -11 9 -3 -9 -5 -40 -6 -31 0 -59 3 -62 8
-3 5 -27 19 -53 31 -27 12 -72 46 -100 76 -29 30 -56 54 -61 54 -4 0 -18 23
-31 50 -27 57 -64 97 -92 102 -11 2 -20 8 -20 13 0 30 182 27 279 -5z m622
-95 c0 -5 -71 -75 -107 -105 -19 -16 -43 -74 -43 -105 0 -60 -52 -145 -88
-145 -13 0 -13 2 0 18 19 21 33 61 29 84 -1 9 3 19 8 23 14 8 -10 35 -24 28
-5 -2 -2 2 7 10 10 8 24 37 31 65 20 78 74 127 121 109 8 -3 22 1 33 8 19 15
33 19 33 10z m-830 -729 l0 -85 53 -3 c45 -3 52 -6 55 -25 3 -22 1 -23 -82
-23 l-86 0 0 110 0 110 30 0 30 0 0 -84z m346 50 c46 -46 48 -105 5 -156 -26
-31 -34 -35 -79 -35 -42 0 -56 5 -80 28 -37 36 -48 84 -28 130 19 46 51 67
104 67 37 0 50 -6 78 -34z m292 18 c12 -8 22 -24 22 -34 0 -23 -36 -36 -55
-20 -23 19 -68 8 -84 -21 -13 -24 -13 -29 2 -53 20 -30 77 -38 77 -11 0 8 -7
15 -15 15 -9 0 -15 9 -15 25 0 24 3 25 55 25 l55 0 0 -38 c0 -42 -28 -77 -78
-98 -53 -22 -135 29 -146 91 -8 41 13 96 45 119 30 20 108 21 137 0z m286 6
c62 -23 81 -128 32 -179 -78 -82 -196 -35 -196 79 0 42 4 52 35 79 38 33 80
40 129 21z m-740 -262 c15 -20 16 -21 34 -5 32 29 42 21 42 -33 0 -51 -13 -67
-23 -28 -5 19 -8 20 -23 7 -13 -11 -18 -11 -21 -1 -7 19 -23 14 -23 -8 0 -11
-7 -20 -15 -20 -11 0 -15 12 -15 48 0 66 16 81 44 40z m187 1 c3 -8 13 -30 22
-49 l15 -36 -54 0 c-56 0 -61 4 -44 34 5 9 15 30 20 45 10 29 28 32 41 6z m99
6 c0 -19 9 -19 31 1 10 9 20 11 23 6 3 -5 -1 -15 -9 -22 -20 -17 -19 -30 7
-57 l21 -23 -21 0 c-11 0 -24 5 -28 11 -4 8 -9 8 -17 0 -24 -24 -37 -11 -37
37 0 27 3 52 7 55 11 12 23 8 23 -8z m178 -2 c2 -8 -5 -13 -17 -13 -12 0 -21
-4 -21 -10 0 -5 8 -10 18 -10 12 0 18 -9 19 -30 2 -29 0 -30 -37 -30 l-40 0 0
48 c0 27 3 52 7 56 11 11 66 2 71 -11z m106 4 c10 -8 18 -29 20 -55 3 -43 -8
-54 -32 -30 -9 9 -12 9 -12 0 0 -7 -7 -12 -15 -12 -16 0 -22 36 -16 98 1 16
32 16 55 -1z"/>
<path d="M8885 1150 c-35 -39 6 -99 58 -86 32 8 45 46 28 82 -14 31 -60 33
-86 4z"/>
<path d="M9492 1158 c-7 -7 -12 -27 -12 -45 0 -57 71 -73 99 -22 13 24 13 29
-2 53 -19 28 -63 36 -85 14z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
